import React, { Component } from 'react';
import '../../css/available-cities-modal.css';
import { cities } from './cities-data';
import indiaFlag from "../../images/cities/india_flag.png";

class AvailableCitiesModal extends Component {
  render() {
    const sortedCities = cities.sort((a, b) => a.name.localeCompare(b.name));

    return (
      <div className="cities-modal">
        <div className="india-header">
          <img src={indiaFlag} alt="India Flag" className="india-flag" loading="lazy"/>&nbsp;&nbsp;
          <h4 className='india-header'>INDIA</h4>
        </div>
        <a class="modal-popup__close" href="#" onClick={this.props.closeModal}>X</a>
        <div className="city-list">
          {sortedCities.map((city) => (
            <div key={city.key} className="city-item">
              {/* <img src={city.image} alt={city.name} /> */}
              <span>{city.name}</span>
            </div>
          ))}
        </div>
        <div className="close-button-row">
          <button onClick={this.props.closeModal}>Close</button>
        </div>
      </div>
    );
  }
}

export default AvailableCitiesModal;
