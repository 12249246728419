import React, { Component } from 'react';

// Layout
import Footer from "../layout/footer";

// Elements
import Header from "../layout/header";
import SliderOneSection from "../elements/home-slider/slider1";
import AboutUsTwoSection from "../elements/about-us-2";
import ServicesSection from "../elements/services-1";
import ChooseUsSection from "../elements/choose-us";
import AppLinks from "../elements/app-links";
import TestimonialSection from "../elements/testimonial";
import PartnerShipsSection from '../elements/partnerships';
import Modal from "../elements/modal";
import { Helmet } from 'react-helmet';
import AvailableCitiesModal from '../elements/available-cities-modal';
import Introduction from '../elements/city_introduction';
import FaqSection from '../elements/faq-section';

// import modalImg from "../../images/background/optimized_modal_img.webp";

// const modalImgBase64 = "data:image/webp;base64,YOUR_BASE64_STRING_HERE";

class Index extends Component {
  componentDidMount() {
    const { setOpenModal, isMobileApp, history } = this.props;

    // Check if phone is provided or ignored, open modal if not
    if (
      localStorage.getItem('phone_provided') !== 'yes' &&
      sessionStorage.getItem('phone_ignored') !== 'yes'
    ) {
      setOpenModal(true);
    }

    // Redirect to /register if it's a mobile app (WebView)
    if (isMobileApp) {
      history.push('/register');
    }
  }

  render() {
    const {
      isMobileApp,
      isMobileBrowser,
      cityKey,
      user,
      setUser,
      openModal,
      setOpenModal,
      citiesModal,
      setCitiesModal,
    } = this.props;

    const canonicalUrl = 'https://www.ridenrepair.com/';

    return (
      <>
        <Helmet>
          <link rel="canonical" href={canonicalUrl} />
          <title>Ride N Repair - Service & Repair at your Doorstep</title>
          <meta
            name="description"
            content="Book your bike service online with Ride N Repair. Expert mechanics at your doorstep for hassle-free repairs."
          />
          <meta
            name="keywords"
            content="Two-Wheeler Service, Two-Wheeler Repair, Bike Maintenance, Scooter Service, Activa Service, Pulsar Service, Hero Honda Service, TVS Service, Motorcycle Repair, Online Booking, Ride N Repair, Doorstep Bike Service, Book Bike Service, Schedule Service, Expert Mechanics, Ahmedabad, Bengaluru, Bhopal, Bhubaneswar, Chandigarh, Dehradun, Delhi, Faridabad, Ghaziabad, Greater Noida, Gurugram, Guwahati, Hyderabad, Indore, Jaipur, Kalyan, Kolkata, Mumbai, Mysore, Nagpur, Nashik, Navi Mumbai, Noida, Pune, Surat, Thane, Vishakhapatnam"
          />

					{/* Preload modal image */}
					{/* <link rel="preload" as="image" href={modalImg} /> */}

          {/* Microsoft Clarity script */}
          <script
            type="text/javascript"
            async
            src="https://www.clarity.ms/tag/ogtpv3dzyx"
          ></script>
        </Helmet>

				{/* Phone Number Modal */}
				{!isMobileApp && openModal && (
					<Modal
						isMobileBrowser={isMobileBrowser}
						isMobileApp={isMobileApp}
						openModal={openModal}
						setOpenModal={setOpenModal}
						user={user}
						setUser={setUser}
					/>
				)}

        {/* Available Cities Modal */}
        {citiesModal && (
          <AvailableCitiesModal closeModal={() => setCitiesModal(false)} />
        )}

        <Header isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} user={user} setUser={setUser} />

        <SliderOneSection isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} cityKey={cityKey} setCitiesModal={setCitiesModal} />

        {/* App Links Section - Hide if in Mobile App */}
        {!isMobileApp && <AppLinks isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} cityKey={cityKey} />}

        <Introduction isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} cityKey={cityKey} />

        {/* FAQ Section - Show if cityKey is provided */}
        {cityKey && <FaqSection isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} cityKey={cityKey} />}

        <AboutUsTwoSection isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} cityKey={cityKey} />

        {/* Uncomment and update ServicesSection as needed */}
        {/* <ServicesSection vehicleType={''} model={''} servicesData={[]} user={user} setUser={setUser} /> */}

        <ChooseUsSection isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} cityKey={cityKey} />

        <TestimonialSection isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} />

        <PartnerShipsSection isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} />

        <Footer isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} setCitiesModal={setCitiesModal} />
      </>
    );
  }
}

export default Index;