// import React, { Component } from 'react';

// import { Route, Switch } from 'react-router-dom';

// // Elements
// import BackToTop from './elements/back-top';
// import PageScrollTop from './elements/page-scroll-top';

// // All Pages Router
// import Index from './pages/index';
// import AboutUs from './pages/about';
// import BlogDetails from './pages/blog-details';
// import BlogListSidebar from './pages/blog-list-sidebar';
// import Booking from './pages/booking';
// import ContactUs from './pages/contact-1';
// import Faq1 from './pages/faq-1';
// import Services from './pages/service-1';
// import ThankYou from './pages/thank-you';
// import ThankYouEmail from './pages/thank-you-email';
// import Error from './pages/error-404';
// import TermsAndConditions from './pages/terms-and-conditions';
// import PrivacyPolicy from './pages/privacy-policy';
// import CancellationPolicy from './pages/cancellation-policy';
// import RefundPolicy from './pages/refund-policy';
// import MechanicLogin from './pages/mechanic-login';
// import MechanicDashboard from './pages/mechanic-dashboard';
// import PartnerLogin from './pages/partner-login';
// import PartnerDashboard from './pages/partner-dashboard';
// import BookingDetails from './pages/booking-details';
// import Login from './pages/login';
// import Register from './pages/register';
// import BookingHistory from './pages/booking-history';


// class Markup extends Component{
// 	render(){
// 		return(
// 			<>

// 				<Switch>
				
// 					<Route path='/' exact component={(props) => (<Index {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp}  citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route 
//             path='/city/:cityName' 
//             exact 
//             render={(routeProps) => (
//               <Index 
//                 {...routeProps}
//                 cityKey={routeProps.match.params.cityName}
// 								isMobileBrowser={this.props.isMobileBrowser} 
// 								isMobileApp={this.props.isMobileApp}
//                 citiesModal={this.props.citiesModal} 
//                 setCitiesModal={this.props.setCitiesModal} 
//                 openModal={this.props.openModal} 
//                 setOpenModal={this.props.setOpenModal} 
//                 user={this.props.user} 
//                 setUser={this.props.setUser} 
//               />
//             )} 
//           />
// 					<Route path='/about' exact component={(props) => (<AboutUs {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/blog-details/:slug' exact component={(props) => (<BlogDetails {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/blogs' exact component={(props) => (<BlogListSidebar {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/booking' exact component={(props) => (<Services {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/checkout' exact component={(props) => (<Booking {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/contact' exact component={(props) => (<ContactUs {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/faq' exact component={(props) => (<Faq1 {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/service' exact component={(props) => (<Services {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/thank-you' exact component={(props) => (<ThankYou {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/thank-you-email' exact component={(props) => (<ThankYouEmail {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/terms-and-conditions' exact component={(props) => (<TermsAndConditions {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/privacy-policy' exact component={(props) => (<PrivacyPolicy {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/cancellation-policy' exact component={(props) => (<CancellationPolicy {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/refund-policy' exact component={(props) => (<RefundPolicy {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} citiesModal={this.props.citiesModal} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/mechanic-login' exact component={(props) => (<MechanicLogin {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/mechanic-dashboard' exact component={(props) => (<MechanicDashboard {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/partner-login' exact component={(props) => (<PartnerLogin {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/login' exact component={(props) => (<Login {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/register' exact component={(props) => (<Register {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/partner-dashboard' exact component={(props) => (<PartnerDashboard {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/booking-history' exact component={(props) => (<BookingHistory {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/booking-details' exact component={(props) => (<BookingDetails {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route path='/error-404' exact component={(props) => (<Error {...props} isMobileBrowser={this.props.isMobileBrowser} isMobileApp={this.props.isMobileApp} setCitiesModal={this.props.setCitiesModal} openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} userVehicles={this.props.userVehicles} setUserVehicles={this.props.setUserVehicles}/>)} />
// 					<Route component={Error} />
					
// 				</Switch>
				
// 				<PageScrollTop />
						
// 				<BackToTop />
				
// 			</>
// 		);
// 	}
// }

// export default Markup;

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

// Elements
import BackToTop from './elements/back-top';
import PageScrollTop from './elements/page-scroll-top';

// All Pages
import Index from './pages/index';
import AboutUs from './pages/about';
import BlogDetails from './pages/blog-details';
import BlogListSidebar from './pages/blog-list-sidebar';
import Booking from './pages/booking';
import ContactUs from './pages/contact-1';
import Faq1 from './pages/faq-1';
import Services from './pages/service-1';
import ThankYou from './pages/thank-you';
import ThankYouEmail from './pages/thank-you-email';
import Error from './pages/error-404';
import TermsAndConditions from './pages/terms-and-conditions';
import PrivacyPolicy from './pages/privacy-policy';
import CancellationPolicy from './pages/cancellation-policy';
import RefundPolicy from './pages/refund-policy';
import MechanicLogin from './pages/mechanic-login';
import MechanicDashboard from './pages/mechanic-dashboard';
import PartnerLogin from './pages/partner-login';
import PartnerDashboard from './pages/partner-dashboard';
import BookingDetails from './pages/booking-details';
import Login from './pages/login';
import Register from './pages/register';
import BookingHistory from './pages/booking-history';

class Markup extends Component {
  render() {
    // Extract common props to pass to all route components
    const commonProps = {
      isMobileBrowser: this.props.isMobileBrowser,
      isMobileApp: this.props.isMobileApp,
      citiesModal: this.props.citiesModal,
      setCitiesModal: this.props.setCitiesModal,
      openModal: this.props.openModal,
      setOpenModal: this.props.setOpenModal,
      user: this.props.user,
      setUser: this.props.setUser,
      userVehicles: this.props.userVehicles,
      setUserVehicles: this.props.setUserVehicles,
    };

    return (
      <>
        <Switch>
          <Route
            path='/'
            exact
            render={(props) => <Index {...props} {...commonProps} />}
          />
          <Route
            path='/city/:cityName'
            exact
            render={(routeProps) => (
              <Index
                {...routeProps}
                cityKey={routeProps.match.params.cityName}
                {...commonProps}
              />
            )}
          />
          <Route
            path='/about'
            exact
            render={(props) => <AboutUs {...props} {...commonProps} />}
          />
          <Route
            path='/blog-details/:slug'
            exact
            render={(props) => (
              <BlogDetails {...props} {...commonProps} />
            )}
          />
          <Route
            path='/blogs'
            exact
            render={(props) => (
              <BlogListSidebar {...props} {...commonProps} />
            )}
          />
          <Route
            path='/booking'
            exact
            render={(props) => <Services {...props} {...commonProps} />}
          />
          <Route
            path='/checkout'
            exact
            render={(props) => <Booking {...props} {...commonProps} />}
          />
          <Route
            path='/contact'
            exact
            render={(props) => <ContactUs {...props} {...commonProps} />}
          />
          <Route
            path='/faq'
            exact
            render={(props) => <Faq1 {...props} {...commonProps} />}
          />
          <Route
            path='/service'
            exact
            render={(props) => <Services {...props} {...commonProps} />}
          />
          <Route
            path='/thank-you'
            exact
            render={(props) => <ThankYou {...props} {...commonProps} />}
          />
          <Route
            path='/thank-you-email'
            exact
            render={(props) => (
              <ThankYouEmail {...props} {...commonProps} />
            )}
          />
          <Route
            path='/terms-and-conditions'
            exact
            render={(props) => (
              <TermsAndConditions {...props} {...commonProps} />
            )}
          />
          <Route
            path='/privacy-policy'
            exact
            render={(props) => (
              <PrivacyPolicy {...props} {...commonProps} />
            )}
          />
          <Route
            path='/cancellation-policy'
            exact
            render={(props) => (
              <CancellationPolicy {...props} {...commonProps} />
            )}
          />
          <Route
            path='/refund-policy'
            exact
            render={(props) => (
              <RefundPolicy {...props} {...commonProps} />
            )}
          />
          <Route
            path='/mechanic-login'
            exact
            render={(props) => (
              <MechanicLogin {...props} {...commonProps} />
            )}
          />
          <Route
            path='/mechanic-dashboard'
            exact
            render={(props) => (
              <MechanicDashboard {...props} {...commonProps} />
            )}
          />
          <Route
            path='/partner-login'
            exact
            render={(props) => (
              <PartnerLogin {...props} {...commonProps} />
            )}
          />
          <Route
            path='/login'
            exact
            render={(props) => <Login {...props} {...commonProps} />}
          />
          <Route
            path='/register'
            exact
            render={(props) => <Register {...props} {...commonProps} />}
          />
          <Route
            path='/partner-dashboard'
            exact
            render={(props) => (
              <PartnerDashboard {...props} {...commonProps} />
            )}
          />
          <Route
            path='/booking-history'
            exact
            render={(props) => (
              <BookingHistory {...props} {...commonProps} />
            )}
          />
          <Route
            path='/booking-details'
            exact
            render={(props) => (
              <BookingDetails {...props} {...commonProps} />
            )}
          />
          <Route
            path='/error-404'
            exact
            render={(props) => <Error {...props} {...commonProps} />}
          />
          <Route component={Error} />
        </Switch>

        <PageScrollTop />
        <BackToTop />
      </>
    );
  }
}

export default Markup;
