import React, {useState, useEffect}  from "react";
import Markup from './markup/markup';
import LogoSchema from "./markup/elements/logo-schema";

// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// React Modal Video
import 'react-modal-video/css/modal-video.min.css';

// Fonts
import "./vendor/line-awesome/css/line-awesome.min.css";
import "./vendor/font-awesome/css/font-awesome.min.css";
import "./vendor/flaticon/flaticon.css";
import "./vendor/themify/themify-icons.css";

// StyleSheet
import './App.scss';

import Router from "./Router";

function App(props) {

	const { ssrLocation, isMobileBrowser: initialIsMobileBrowser, isMobileApp } = props;
	
	const [user, setUser] = useState({ is_logged: false, email: '', fn: '', ln: '', ph: '' });
	const [userVehicles, setUserVehicles] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [citiesModal, setCitiesModal] = useState(false);

	const [isMobileBrowser, setIsMobileBrowser] = useState(initialIsMobileBrowser);

	useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      const isMobile = window.innerWidth <= 1024;
      setIsMobileBrowser(isMobile);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Call handler once to set the initial state based on current window size
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'PageView'
      });
    }
  }, []);
	
	return (
		<Router ssrLocation={ssrLocation}>
			<div className="page-wraper">
				<LogoSchema />
				<Markup isMobileBrowser={isMobileBrowser} isMobileApp={isMobileApp} citiesModal={citiesModal} setCitiesModal={setCitiesModal} openModal={openModal} setOpenModal={setOpenModal} user={user} setUser={setUser} userVehicles={userVehicles} setUserVehicles={setUserVehicles} />
			</div>
		</Router>
	);
}

export default App;